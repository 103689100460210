/**
 * @generated SignedSource<<7954aa008bcfc204dc68ec38bf027302>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IssueViewerSecondaryRepoData$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LazyContributorFooter">;
  readonly " $fragmentType": "IssueViewerSecondaryRepoData";
};
export type IssueViewerSecondaryRepoData$key = {
  readonly " $data"?: IssueViewerSecondaryRepoData$data;
  readonly " $fragmentSpreads": FragmentRefs<"IssueViewerSecondaryRepoData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IssueViewerSecondaryRepoData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LazyContributorFooter"
    }
  ],
  "type": "Repository",
  "abstractKey": null
};

(node as any).hash = "776d603a4334dfe4ac8d0e7ffe08b5da";

export default node;
