import {useFragment, graphql} from 'react-relay'
import {DevelopmentSection, DevelopmentSectionFallback} from './sections/development-section/DevelopmentSection'
import {SubscriptionSection, SubscriptionSectionFallback} from './sections/SubscriptionSection'
import type {IssueSidebarLazySections$key} from './__generated__/IssueSidebarLazySections.graphql'
import {ParticipantsSection} from './sections/ParticipantsSection'
import {
  RelationshipsSectionFallback,
  RelationshipsSectionInternal,
} from './sections/relations-section/RelationshipsSection'

type IssueSidebarLazySectionsBaseProps = {
  onLinkClick?: (event: MouseEvent) => void
  onIssueUpdate?: () => void
  singleKeyShortcutsEnabled: boolean
  sub_issues?: boolean
  insideSidePanel?: boolean
}

type IssueSidebarLazySectionsProps = IssueSidebarLazySectionsBaseProps & {
  issueSidebarSecondaryKey?: IssueSidebarLazySections$key
}

type FallbackProps = {
  sub_issues?: boolean
}

export function IssueSidebarLazySections({
  issueSidebarSecondaryKey,
  onIssueUpdate,
  onLinkClick,
  singleKeyShortcutsEnabled,
  sub_issues,
  insideSidePanel,
}: IssueSidebarLazySectionsProps) {
  const data = useFragment(
    graphql`
      fragment IssueSidebarLazySections on Issue {
        ...DevelopmentSectionFragment
        ...RelationshipsSectionFragment
        ...SubscriptionSectionFragment
        ...ParticipantsSectionFragment
      }
    `,
    issueSidebarSecondaryKey,
  )

  if (!data) {
    return <IssueSidebarLazySectionsFallback sub_issues={sub_issues} />
  }

  return (
    <>
      {sub_issues && (
        <RelationshipsSectionInternal issue={data} onLinkClick={onLinkClick} insideSidePanel={insideSidePanel} />
      )}
      <DevelopmentSection
        shortcutEnabled={singleKeyShortcutsEnabled}
        issue={data}
        onIssueUpdate={onIssueUpdate}
        insideSidePanel={insideSidePanel}
      />
      <SubscriptionSection issue={data} />
      <ParticipantsSection issue={data} />
    </>
  )
}

export function IssueSidebarLazySectionsFallback({sub_issues}: FallbackProps) {
  return (
    <>
      {sub_issues && <RelationshipsSectionFallback />}
      <DevelopmentSectionFallback />
      <SubscriptionSectionFallback />
    </>
  )
}

try{ IssueSidebarLazySections.displayName ||= 'IssueSidebarLazySections' } catch {}
try{ IssueSidebarLazySectionsFallback.displayName ||= 'IssueSidebarLazySectionsFallback' } catch {}