import {Box, Button} from '@primer/react'
import type React from 'react'
import {useCallback, useState} from 'react'

import {IssueTitleInput} from '../IssueTitleInput'
import {useRelayEnvironment} from 'react-relay'
import {commitUpdateIssueTitleMutation} from '../../mutations/update-issue-title-mutation'
// eslint-disable-next-line no-restricted-imports
import {useToastContext} from '@github-ui/toast/ToastContext'
import {BUTTON_LABELS} from '../../constants/buttons'
import {ERRORS} from '../../constants/errors'
import {VALIDATORS} from '@github-ui/entity-validators'

type Props = {
  title: string
  titleRef: React.RefObject<HTMLInputElement>
  onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onIssueUpdate?: () => void
  cancelIssueTitleEdit: () => void
  issueId: string
  isDirty: boolean
  isSubmitting: boolean
  setIsSubmitting: (isSubmitting: boolean) => void
}

export function HeaderEditor({
  onTitleChange,
  title,
  cancelIssueTitleEdit,
  issueId,
  isDirty,
  onIssueUpdate,
  isSubmitting,
  setIsSubmitting,
  ...props
}: Props) {
  const relayEnvironment = useRelayEnvironment()
  const {addToast} = useToastContext()
  const [validationError, setValidationError] = useState<string | undefined>(undefined)

  const commitIssueTitleEdit = useCallback(() => {
    commitUpdateIssueTitleMutation({
      environment: relayEnvironment,
      input: {issueId, title},
      onError: () => {
        setIsSubmitting(false)
        // eslint-disable-next-line @github-ui/dotcom-primer/toast-migration
        addToast({
          type: 'error',
          message: ERRORS.couldNotUpdateIssueTitle,
        })
      },
      onCompleted: () => {
        setIsSubmitting(false)
        onIssueUpdate?.()
        cancelIssueTitleEdit()
      },
    })
  }, [relayEnvironment, issueId, title, addToast, setIsSubmitting, onIssueUpdate, cancelIssueTitleEdit])

  const isEmpty = title.match(/^ *$/) !== null

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '8px', width: '100%'}}>
      <IssueTitleInput
        value={title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (validationError) {
            setValidationError(undefined)
          }
          onTitleChange(e)
        }}
        cancelIssueTitleEdit={cancelIssueTitleEdit}
        commitIssueTitleEdit={commitIssueTitleEdit}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        validationError={validationError}
        setIsSubmitting={setIsSubmitting}
        {...props}
      />
      <Button onClick={cancelIssueTitleEdit} disabled={isSubmitting}>
        {BUTTON_LABELS.cancel}
      </Button>
      {saveButton(
        () => {
          setIsSubmitting(true)
          commitIssueTitleEdit()
        },
        isEmpty,
        setValidationError,
        isSubmitting,
      )}
    </Box>
  )
}

const saveButton = (
  onClick: () => void,
  isEmpty: boolean,
  setValidationError: (error: string | undefined) => void,
  disabled: boolean,
) => {
  const validationError = isEmpty ? VALIDATORS.titleCanNotBeEmpty : undefined

  return (
    <Button
      variant="primary"
      disabled={disabled}
      onClick={() => {
        if (validationError) {
          setValidationError(validationError)
        } else {
          onClick()
        }
      }}
    >
      {BUTTON_LABELS.submitIssueTitleEdit}
    </Button>
  )
}

try{ HeaderEditor.displayName ||= 'HeaderEditor' } catch {}