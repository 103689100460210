import type {QueryClient, QueryFilters} from '@tanstack/react-query'

import {isQueryDataGrouped} from '../queries/query-data-helpers'
import {
  buildPageParamsQueryKey,
  createGroupedItemsId,
  getPageTypeFromQueryKey,
  type GroupedMemexItemsQueryKey,
  isPageTypeForGroupedItems,
  isQueryForItemsMetadata,
  isQueryKeyForGroupedItems,
  pageTypeForGroups,
  type PaginatedMemexItemsQueryKey,
  paginatedMemexItemsQueryKey,
} from '../queries/query-keys'
import {
  type MemexPageQueryData,
  pageParamForInitialPage,
  pageParamForNextPlaceholder,
  type PageParamsByGroupedItemsId,
  type PageParamsQueryData,
  type PaginatedMemexItemsQueryVariables,
} from '../queries/types'
import {getQueryDataForMemexGroupsPage} from './memex-groups'

/**
 * A thin wrapper around `getQueryData`, that returns the page params for which query keys should be
 * active in our view
 * @param queryClient
 * @param variables PaginatedMemexItemsQueryVariables to use in our query key
 * @returns PageParamsQueryData
 */
export function getPageParamsQueryDataForVariables(
  queryClient: QueryClient,
  variables: PaginatedMemexItemsQueryVariables,
) {
  return (
    queryClient.getQueryData<PageParamsQueryData>(buildPageParamsQueryKey(variables)) || {
      pageParams: [],
    }
  )
}

/**
 * A thin wrapper around `setQueryData`, that sets the page params for which query keys should be
 * active in our view
 * @param queryClient
 * @param variables PaginatedMemexItemsQueryVariables that is used in our queryKey
 * @param newQueryData
 */
export function setPageParamsQueryDataForVariables(
  queryClient: QueryClient,
  variables: PaginatedMemexItemsQueryVariables,
  newQueryData: PageParamsQueryData,
) {
  queryClient.setQueryData<PageParamsQueryData>(buildPageParamsQueryKey(variables), newQueryData)
}

/**
 * Removes inactive queryData except the first page for each view.
 * Invalidates that first page so that it will be refetched
 * the next time the view becomes active.
 * @param queryClient
 */
export const invalidateInactiveInitialQueries = (queryClient: QueryClient) => {
  const baseInactiveMemexItemsQueryFilter: QueryFilters = {
    queryKey: [paginatedMemexItemsQueryKey],
    exact: false,
    type: 'inactive',
  }
  // When a user returns to a view, their scroll position will be reset to the top.
  // So we don't need to keep subsequent pages of data.
  queryClient.removeQueries({
    ...baseInactiveMemexItemsQueryFilter,
    predicate: query => {
      if (isQueryForItemsMetadata(query)) {
        return false
      }
      const queryKey = query.queryKey as PaginatedMemexItemsQueryKey
      if (queryKey[3] === pageParamForInitialPage && isQueryKeyForGroupedItems(queryKey)) {
        // Remove initial page of groups that aren't in the first top-level page of groups
        return !isGroupInInitialPageOfGroups(queryClient, queryKey)
      }
      if (queryKey[3] === pageParamForInitialPage && !isQueryKeyForGroupedItems(queryKey)) {
        resetPageParamsQueryDataToInitialPage(queryClient, queryKey[1])
      }
      return queryKey[3] !== pageParamForInitialPage && queryKey[3] !== pageParamForNextPlaceholder
    },
  })
  // We want to invalidate the first page of data so that it will be refetched
  // when the view becomes active.
  queryClient.invalidateQueries({
    ...baseInactiveMemexItemsQueryFilter,
    refetchType: 'active',
    predicate: query => {
      const queryKey = query.queryKey as PaginatedMemexItemsQueryKey
      return (
        !isQueryForItemsMetadata(query) &&
        queryKey[3] === pageParamForInitialPage &&
        // We only need to invalidate the top level query for groups
        !isQueryKeyForGroupedItems(queryKey)
      )
    },
  })
}

/**
 * Resets each page params query to only include the initial page param.
 * @param queryClient
 * @param variables The variables used in the page params query key
 */
function resetPageParamsQueryDataToInitialPage(queryClient: QueryClient, variables: PaginatedMemexItemsQueryVariables) {
  const pageParamsQueryData = getPageParamsQueryDataForVariables(queryClient, variables)
  if (!pageParamsQueryData || pageParamsQueryData.pageParams.length === 0) return
  if (pageParamsQueryData.pageParams[pageParamsQueryData.pageParams.length - 1] === pageParamForNextPlaceholder) {
    // Skip any page params queries that are tracking placeholder data
    return
  }
  // If the data is grouped, we should reset the page params
  // of any group in the initial top-level page of groups
  const groupData = queryClient.getQueryData<MemexPageQueryData>([
    paginatedMemexItemsQueryKey,
    variables,
    pageTypeForGroups,
    pageParamForInitialPage,
  ])
  const groups = groupData && isQueryDataGrouped(groupData) ? groupData.groups : []
  const groupPageParams = {
    groups: groups.reduce((acc, group) => {
      acc[group.groupId] = [pageParamForInitialPage]
      return acc
    }, {} as PageParamsByGroupedItemsId),
  }
  setPageParamsQueryDataForVariables(queryClient, variables, {
    ...(groups.length > 0 ? groupPageParams : {}),
    pageParams: [pageParamForInitialPage],
  })
}

/**
 * Checks if a queryKey is for a group in the initial
 * page of groups returned by the server
 * @param queryClient
 * @param groupQueryKey The queryKey for the items group to verify
 * @returns boolean
 */
function isGroupInInitialPageOfGroups(queryClient: QueryClient, groupQueryKey: GroupedMemexItemsQueryKey) {
  const initialPageOfGroups = getQueryDataForMemexGroupsPage(queryClient, groupQueryKey[1], pageParamForInitialPage)
  const groupIdsInInitialPage = initialPageOfGroups?.groups.map(g => g.groupId) || []
  const pageType = getPageTypeFromQueryKey(groupQueryKey)
  const groupedItemsId = isPageTypeForGroupedItems(pageType) ? createGroupedItemsId(pageType) : ''
  return groupIdsInInitialPage.includes(groupedItemsId)
}

// The next_placeholder should always be the last page param, so hasNextPage must be false.
export const nextPlaceholderPageInfo = {hasNextPage: false, hasPreviousPage: false}
