export const LABELS = {
  applyButtonLabel: 'Apply',
  archived: 'Archived',
  cancelButtonLabel: 'Cancel',
  editTitle: 'Rename issue',
  emptySections: {
    branchesOrLinkedPullRequests: 'No branches or linked pull requests',
    relationships: 'None yet',
    participants: 'No participants',
    development: 'No branches or pull requests',
  },
  filterProjects: 'Filter projects',
  issueNumber: (issueNumber: number) => `#${issueNumber}`,
  issueTypes: {
    noIssueTypeOptionName: 'None',
    issueTypeDialogHeader: 'Select issue type',
  },
  lock: {
    title: 'Lock conversation on this issue',
    titleUnlock: 'Unlock conversation on this issue',
    buttonConfirmLock: 'Lock conversation',
    buttonConfirmUnlock: 'Unlock conversation',
  },
  noDescriptionProvided: 'No description provided.',
  notifications: {
    unsubscribedAnnouncement: "You're now unsubscribed from this issue.",
    unsubscribedButton: 'Subscribe',
    unsubscribedDescription: "You're not receiving notifications from this thread.",
    subscribedAnnouncement: "You're now subscribed to this issue.",
    subscribedButton: 'Unsubscribe',
    subscribedDescription: "You're receiving notifications because you're subscribed to this thread.",
  },
  optionsTitle: 'Issue actions',
  pinIssueTooltip: 'Up to 3 issues can be pinned to the top of the issues page',
  private: 'Private',
  prPickerDescription: 'Link pull requests',
  public: 'Public',
  pullRequest: 'Pull request',
  repository: 'Repository',
  sectionTitles: {
    relationships: 'Relationships',
    development: 'Development',
    participants: 'Participants',
  },
  relationNames: {
    parentIssue: 'Parent issue',
  },
  development: {
    createBranch: 'Create a branch',
    repositoryPickerNav: 'Return to repository picker',
    createBranchSuffix: 'for this issue or link a pull request.',
    repoPickerSubtitle: 'Select a repository to search for branches and pull requests or',
    prsBranchesPickerSubtitle: 'Link a branch, pull request, or',
    copilot: {
      open: 'Open in Workspace',
    },
  },
  selectProjects: 'Select projects',
  tasks: (completed: number, total: number) =>
    completed === 0 ? `${total} task${total > 1 ? 's' : ''}` : `${completed} of ${total}`,
  timeline: {
    pinned: 'pinned this issue',
  },
  transfer: {
    title: 'Transfer issue',
    subtitle:
      'This does not scrub any issue content. Content such as text references to other issues, pull requests, projects and teams will still appear in the description or comments. Labels will be transferred.',
    started: 'Issue transfer has started. This action might take a while.',
    repoUnavailable: 'Issue cannot be transferred to this repository.',
  },
  convertToDiscussion: {
    title: 'Convert issue to a discussion',
    whatHappens: 'What happens when an issue is converted into a discussion:',
    affirmations: {
      closedAndLocked: 'Issue will be closed and locked',
      same: 'Title, description, and author will be the same as the issue',
      commentsAndReactions: 'All comments and reactions will be the same as the issue',
    },
    warnings: {
      taskListBlocks: 'Discussions do not have tasklists',
      assignees: 'Discussions do not have assignees',
      projects: 'Discussions cannot be added to projects',
      milestone: 'Discussions do not have milestones',
    },
    selectCategoryTitle: 'Category for new discussion',
    error: (message: string) => `ConvertIssueToDiscussion mutation failed with error: ${message}`,
  },
  unpinIssueTooltip: 'This will unpin this issue from the top of the issues page',
  unsavedChangesContent: 'Are you sure you want to discard them?',
  unsavedChangesTitle: 'You have unsaved changes',
  viewDescriptionPlaceholder: 'Add a description',
  viewTitlePlaceholder: 'Title',
  linkedPrsLabel: 'Linked pull requests',
  mergedPrs: 'Fixed by',
  openPrs: 'Remaining',
  somethingWentWrong: 'Something went wrong! Please try again',
}
