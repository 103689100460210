import {useKeyPress} from '@github-ui/use-key-press'
import {FormControl, TextInput, useConfirm} from '@primer/react'
import {type ChangeEvent, useMemo} from 'react'
import type React from 'react'

import {HOTKEYS} from '../constants/hotkeys'
import {LABELS} from '../constants/labels'
import {TEST_IDS} from '../constants/test-ids'
import {InlineAutocomplete} from '@github-ui/inline-autocomplete'
import {useEmojiSuggestions} from '@github-ui/markdown-editor/suggestions/use-emoji-suggestions'
import {emojiList} from '@github-ui/comment-box/emojis'
import {useAutocompleteTriggersAndSuggestions} from '@github-ui/inline-autocomplete/hooks/use-autocomplete-triggers-and-suggestions'

type IssueTitleInputProps = {
  titleRef: React.RefObject<HTMLInputElement>
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  commitIssueTitleEdit: () => void
  cancelIssueTitleEdit: () => void
  isDirty: boolean
  validationError?: string
  isSubmitting: boolean
  setIsSubmitting: (isSubmitting: boolean) => void
}

export function IssueTitleInput({
  titleRef,
  value,
  onChange,
  commitIssueTitleEdit,
  cancelIssueTitleEdit,
  isDirty,
  validationError,
  isSubmitting,
  setIsSubmitting,
}: IssueTitleInputProps) {
  const confirm = useConfirm()

  useKeyPress(
    [HOTKEYS.closeEdit],
    async (event: KeyboardEvent) => {
      event.preventDefault()
      if (isDirty) {
        // Prompt user to acknowledge they want to exit without saving
        const discardChanges = await confirm({
          title: LABELS.unsavedChangesTitle,
          content: LABELS.unsavedChangesContent,
          confirmButtonType: 'danger',
        })
        if (!discardChanges) {
          return
        }
      }
      cancelIssueTitleEdit()
    },
    {triggerWhenInputElementHasFocus: true},
  )

  const emptyArray: [] = [] // constant reference to avoid re-running effects
  const {trigger, calculateSuggestions} = useEmojiSuggestions(emojiList ?? emptyArray)
  const triggersAndSuggestions = useMemo(
    () => [{trigger, suggestionsCalculator: calculateSuggestions}],
    [calculateSuggestions, trigger],
  )
  const {
    triggers,
    suggestions,
    setSuggestionEvent,
    onHideSuggestions,
    active: suggestionsActive,
  } = useAutocompleteTriggersAndSuggestions(triggersAndSuggestions)

  useKeyPress(
    [HOTKEYS.commitEdit],
    (event: KeyboardEvent) => {
      if (suggestionsActive) {
        return
      }
      event.preventDefault()
      if (isDirty && value.length > 0) {
        setIsSubmitting(true)
        commitIssueTitleEdit()
      } else {
        cancelIssueTitleEdit()
      }
    },
    {
      triggerWhenInputElementHasFocus: true,
      scopeRef: titleRef,
      ignoreModifierKeys: true,
      triggerWhenPortalIsActive: true,
    },
  )

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
      disabled={isSubmitting}
    >
      <FormControl.Label visuallyHidden>Title input</FormControl.Label>
      <InlineAutocomplete
        triggers={triggers}
        suggestions={suggestions}
        onShowSuggestions={setSuggestionEvent}
        onHideSuggestions={onHideSuggestions}
        fullWidth
      >
        <TextInput
          sx={{width: '100%'}}
          ref={titleRef}
          onChange={onChange}
          value={value}
          placeholder={LABELS.viewTitlePlaceholder}
          data-testid={TEST_IDS.issueTitleInput}
        />
      </InlineAutocomplete>
      {validationError && <FormControl.Validation variant="error">{validationError}</FormControl.Validation>}
    </FormControl>
  )
}

try{ IssueTitleInput.displayName ||= 'IssueTitleInput' } catch {}