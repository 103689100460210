export const LABELS = {
  repositoryOwner: 'Repository owner',
  metadataHeader: 'Metadata',
  metadataLabel: 'Details',
  timeline: {
    header: 'Activity',
    added: 'added',
    removed: 'removed',
    removedTheirAssignment: 'removed their assignment',
    unassigned: 'unassigned',
    locked: 'locked',
    limitedToCollaborators: 'and limited conversation to collaborators',
    unlockedConversation: 'unlocked this conversation',
    pinned: 'pinned this issue',
    unpinned: 'unpinned this issue',
    closedThis: 'closed this',
    subscribed: 'subscribed',
    unsubscribed: 'unsubscribed',
    mentioned: 'mentioned this',
    as: 'as',
    in: 'in',
    to: 'to',
    and: 'and',
    reopenedThis: 'reopened this',
    selfAssignedThis: 'self-assigned this',
    assigned: 'assigned',
    temporarily: 'temporarily',
    blocked: 'blocked',
    deletedACommentFrom: 'deleted a comment from',
    addedToMilestone: 'added this to the',
    removedFromMilestone: 'removed this from the',
    milestone: 'milestone',
    milestoneDeleted: 'This milestone has been deleted',
    renamedTitle: 'changed the title',
    mentionedThisIn: 'mentioned this',
    linkedAClosingPR: 'linked a pull request that will close this issue',
    addedCommitsThatReferences: (numCommits: number) =>
      `added ${numCommits === 1 ? 'a' : numCommits} commit${numCommits === 1 ? '' : 's'} that reference${
        numCommits === 1 ? 's' : ''
      } this issue`,
    transferredThis: 'transferred this issue from',
    issueFromNote: 'created this issue from a note in',
    removedLinkedPR: 'removed a link to a pull request',
    markedAsDuplicate: 'marked this as a duplicate of',
    unmarkedAsDuplicate: 'marked this as not a duplicate of',
    convertedToDiscussion: 'converted this issue into a discussion',
    convertedFromDraftIssue: 'converted this from a draft issue',
    addedThisTo: 'added this to',
    removedThisFrom: 'removed this from',
    movedThisFrom: 'moved this from',
    movedThisTo: 'moved this to',
    loadMore: (numberOfTimelineItems: number) => `Load ${numberOfTimelineItems} more`,
    loadAll: 'Load all',
    loadNewer: 'Load newer activity',
    loadOlder: 'Load older activity',
    announcements: {
      loadRemaining: 'Loading remaining timeline items',
      loadOlder: 'Loading older timeline items',
      loadNewer: 'Loading newer timeline items',
    },
  },
  crossReferencedEventLockTooltip: (repoNameWithOwner: string) =>
    `Only people who can see ${repoNameWithOwner} will see this reference.`,
  crossReferencedEvent: {
    sectionLabel: 'Issues mentioned',
    privateEventDescription: 'This event is from a private repository and is only visible to repository members',
  },
  commitWillCloseMessage: (subjectType: string, abbreviatedOid: string, defaultBranch: string) =>
    `This ${
      subjectType === 'Issue' ? 'issue' : 'pull request'
    } will close once commit ${abbreviatedOid} is merged into the '${defaultBranch}' branch.`,
  undoMarkIssueAsDuplicate: (cannonicalIssueNumber: number) =>
    `Undo marking issue as duplicate of issue #${cannonicalIssueNumber}`,
}
