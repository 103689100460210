import isEqual from 'lodash-es/isEqual'
import {useEffect, useMemo, useRef} from 'react'

import {DefaultSearchConfig} from '../../../components/filter-bar/search-context'
import {useHorizontalGroupedBy} from '../../../features/grouping/hooks/use-horizontal-grouped-by'
import {useVerticalGroupedBy} from '../../../features/grouping/hooks/use-vertical-grouped-by'
import {useSliceBy} from '../../../features/slicing/hooks/use-slice-by'
import {useEnabledFeatures} from '../../../hooks/use-enabled-features'
import {useSortedBy} from '../../../hooks/use-sorted-by'
import {useViews} from '../../../hooks/use-views'
import {useVisibleFieldIds} from '../../../hooks/use-visible-field-ids'
import type {PaginatedMemexItemsQueryVariables, SliceByQueryVariables} from './types'
import {useNextPlaceholderQuery} from './use-next-placeholder-query'

type QueryVariableOptions = {
  withSliceByValue: boolean
}

export function usePaginatedMemexItemsQueryVariables(options: {
  withSliceByValue: true
}): PaginatedMemexItemsQueryVariables
export function usePaginatedMemexItemsQueryVariables(options: {withSliceByValue: false}): SliceByQueryVariables
export function usePaginatedMemexItemsQueryVariables({withSliceByValue}: QueryVariableOptions) {
  const {memex_mwl_swimlanes} = useEnabledFeatures()
  const {currentView} = useViews()
  const q = DefaultSearchConfig.getSearchQueryFromView(currentView)
  const {sorts} = useSortedBy()
  const sortedBy = useMemo(
    () => sorts.map(sort => ({columnId: sort.column.databaseId, direction: sort.direction})),
    [sorts],
  )
  let {groupedByColumnId: horizontalGroupedByColumnId} = useHorizontalGroupedBy()
  let {groupedByColumnId: verticalGroupedByColumnId} = useVerticalGroupedBy()

  // only board views use vertical grouping, but it's always defaulted for any view type
  if (currentView?.localViewStateDeserialized.viewType !== 'board') {
    verticalGroupedByColumnId = undefined
  } else if (currentView?.localViewStateDeserialized.viewType === 'board' && !memex_mwl_swimlanes) {
    // If we're on a board view with swimlanes disabled, then we don't want to include
    // a horizontal grouped by column id in the request, even if we somehow have one in the view
    horizontalGroupedByColumnId = undefined
  }

  const {sliceField, sliceValue} = useSliceBy()
  const sliceByColumnId = sliceField?.id

  const {memex_mwl_limited_field_ids} = useEnabledFeatures()
  const {visibleFieldIds} = useVisibleFieldIds()
  const activeFieldIdsRef = useRef(visibleFieldIds)
  const fieldIds = useMemo(
    () => (memex_mwl_limited_field_ids ? visibleFieldIds : undefined),
    [memex_mwl_limited_field_ids, visibleFieldIds],
  )

  const variables: PaginatedMemexItemsQueryVariables | SliceByQueryVariables = useMemo(
    () => ({
      q,
      sortedBy,
      horizontalGroupedByColumnId,
      verticalGroupedByColumnId,
      sliceByColumnId,
      // don't include `sliceValue` if withSliceValue is false
      // sliceValue can be `null`, which we want to coerce to undefined
      sliceByValue: withSliceByValue ? sliceValue ?? undefined : undefined,
      fieldIds,
    }),
    [
      q,
      sortedBy,
      horizontalGroupedByColumnId,
      verticalGroupedByColumnId,
      sliceByColumnId,
      withSliceByValue,
      sliceValue,
      fieldIds,
    ],
  )

  // We want to continue to display the previously loaded view data
  // while we refetch the view using the updated fieldIds.
  // We do this by seeding the new query with a placeholder
  // based on the previous fieldIds, before returning the active variables.
  const {setUpNextPlaceholderQueries} = useNextPlaceholderQuery(variables, activeFieldIdsRef.current)
  useEffect(() => {
    if (memex_mwl_limited_field_ids && !isEqual(visibleFieldIds, activeFieldIdsRef.current)) {
      // Note: when visibleFieldIds changes, this useEffect will be (re)evaluated at
      // each usePaginatedMemexItemsQueryVariables callsite. This is fine, because
      // setUpNextPlaceholderQueries guards against setting up placeholders more than once
      // for the same field ids.
      setUpNextPlaceholderQueries()
      activeFieldIdsRef.current = visibleFieldIds
    }
  }, [visibleFieldIds, memex_mwl_limited_field_ids, setUpNextPlaceholderQueries])

  // Ideally this extra check wouldn't be necessary, but because the formation of the variables
  // is within a `useMemo`, TypeScript can't infer the type of the variables object based on the
  // withSliceValue param like we'd hope.
  if (withSliceByValue) {
    return variables as PaginatedMemexItemsQueryVariables
  } else {
    return variables as SliceByQueryVariables
  }
}
