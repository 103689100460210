import {useMemo} from 'react'

import {filterFalseyValues} from '../helpers/util'
import {useFindColumnByDatabaseId} from '../state-providers/columns/use-find-column-by-database-id'
import {useViews} from './use-views'

/*
 * A hook to access the synthetic ids of visible fields for the current view.
 * Used to limit the fields returned in paginated items requests.
 */
export function useVisibleFieldIds() {
  const {currentView} = useViews()
  const {findColumnByDatabaseId} = useFindColumnByDatabaseId()
  // useViews stores visible fields for the current view as database ids
  const {visibleFields: databaseIds} = currentView?.localViewState ?? {}
  // But the server expects synthetic field ids in the request
  const visibleFieldIds = useMemo(() => {
    const ids = filterFalseyValues(databaseIds?.map(databaseId => findColumnByDatabaseId(databaseId)?.id) || [])
    return ids.length === 0 ? undefined : ids.sort()
  }, [databaseIds, findColumnByDatabaseId])
  return {visibleFieldIds}
}
